{

    const OPERATORS = {
        EQUAL: {CAPTION: "=", VALUE: "EQUAL"},
        LIKE: {CAPTION: "~", VALUE: "LIKE"},
        LESS_THAN: {CAPTION: "<", VALUE: "LESS_THAN"},
        LESS_THAN_OR_EQUAL: {CAPTION: "<=", VALUE: "LESS_THAN_OR_EQUAL"},
        GREATER_THAN: {CAPTION: ">", VALUE: "GREATER_THAN"},
        GREATER_THAN_OR_EQUAL: {CAPTION: ">=", VALUE: "GREATER_THAN_OR_EQUAL"}
    };

    const VaultFilterOperatorsConstant = {
        LTAG: {
            OPERATOR: [OPERATORS.EQUAL, OPERATORS.LESS_THAN, OPERATORS.LESS_THAN_OR_EQUAL, OPERATORS.GREATER_THAN,
                OPERATORS.GREATER_THAN_OR_EQUAL]
        },
        TAG: {
            OPERATOR: [OPERATORS.EQUAL, OPERATORS.LIKE]
        },
        DATE: {
            OPERATOR: [OPERATORS.EQUAL, OPERATORS.LESS_THAN, OPERATORS.LESS_THAN_OR_EQUAL, OPERATORS.GREATER_THAN,
                OPERATORS.GREATER_THAN_OR_EQUAL]
        },
        EMAIL: {
            OPERATOR: [OPERATORS.EQUAL, OPERATORS.LIKE]
        },
        ORGANIZATION_ID: {
            OPERATOR: [OPERATORS.EQUAL]
        },
        FILESTATUS: {
            OPERATOR: [OPERATORS.EQUAL]
        }
    };


    const transform = (VaultFilterOperatorsConstant) => Object.entries(VaultFilterOperatorsConstant)
        .reduce((acc, item) => {
            acc[VaultDataItemTypeConstant[item[0]]] = item[1];
            return acc;
        }, {});


    angular.module('adminApp')
        .constant('VaultFilterOperatorsConstant', transform(VaultFilterOperatorsConstant));
}
